
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      VendorPages: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchVendorPages: null,
      VendorPagesSearchMode: false,
      searchModel: "",
      state: state
    };
  },
  methods: {
    addVendorPages() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.addVendorPages",
        inputs: [
          { model: "vendor_id", type: "text", label: "vendor_id" },
          { model: "page_type", type: "text", label: "page_type" },
          { model: "title", type: "text", label: "title" },
          { model: "content", type: "text", label: "content" },
          { model: "image", type: "text", label: "image" },
          { model: "vedio", type: "text", label: "vedio" },
          { model: "status", type: "text", label: "status" }
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: obj => {
              this.http.post("vendor-pages", obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    editVendorPages(app) {
      var data = {
        title: "popups.vendor_pages",
        inputs: [
          {
            model: "vendor_id",
            type: "text",
            label: "vendor_id",
            value: app.vendor_id
          },
          {
            model: "page_type",
            type: "text",
            label: "page_type",
            value: app.page_type
          },
          { model: "title", type: "text", label: "title", value: app.title },
          {
            model: "content",
            type: "text",
            label: "content",
            value: app.content
          },
          { model: "image", type: "text", label: "image", value: app.image },
          { model: "vedio", type: "text", label: "vedio", value: app.vedio },
          { model: "status", type: "text", label: "status", value: app.status }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: obj => {
              this.http.put("vendor-pages", app.id, obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    updateVendorPages(app) {
      var data = {
        title: "popups.editfile",
        inputs: [
          { model: "vendor_id", type: "text", label: "vendor_id" },
          { model: "page_type", type: "text", label: "page_type" },
          { model: "title", type: "text", label: "title" },
          { model: "content", type: "text", label: "content" },
          { model: "image", type: "text", label: "image" },
          { model: "vedio", type: "text", label: "vedio" },
          { model: "status", type: "text", label: "status" }
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: obj => {
              obj.emp_id = app.id;
              this.http.do("vendor-pages", obj).then(() => {
                this.get(this.page);
              });
            }
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            }
          }
        ]
      };
      this.popup.modal(data);
    },
    search() {
      this.VendorPagesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("vendor-pages/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: ""
        })
        .then(res => {
          this.VendorPages = res.data;
          console.log("######## files", this.VendorPages);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.VendorPagesSearchMode = false;
      this.get(this.page);
    },

    getVendorPages() {
      this.http.get("vendor-pages").then(res => {
        this.VendorPages = res.data;
      });
    },
    deleteVendorPages(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage"
      };
      this.popup.confirm(data).then(resp => {
        if (resp) {
          console.log("###");
          this.http.delete("vendor-pages", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("vendor-pages/paginate", {
          limit: this.limit,
          page: page
        })
        .then(res => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.VendorPages = res.data;
        });
    }
  },
  created() {
    this.get(1);
    this.getVendorPages();
  }
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.vendor_pages.text')"
    />
    <div class="row">
      <div class="col-6">
        <div class="search-box chat-search-box w-50">
          <div class="position-relative">
            <input
              @keyup="search()"
              type="text"
              class="form-control fa-lg text-light"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              :placeholder="$t('popups.search')"
              v-model="searchModel"
              style="background-color: #2a3042 !important"
            />
            <i class="bx bx-search-alt search-icon text-light"></i>
          </div>
        </div>
      </div>
      <div class="col-6" style="display: flex; justify-content: flex-end">
        <button
          class="btn-close text-light"
          v-if="VendorPagesSearchMode"
          @click="cancelappsearchMode()"
        ></button>
        <button type="button" @click="addVendorPages()" class="btn btn-light float-end mb-4">
          <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
        </button>
      </div>
    </div>

    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table mt-1">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope="col">{{$t('vendor_pages.id')}}</th>
          <th scope="col">{{$t('vendor_pages.vendor_id')}}</th>
          <th scope="col">{{$t('vendor_pages.page_type')}}</th>
          <th scope="col">{{$t('vendor_pages.title')}}</th>
          <th scope="col">{{$t('vendor_pages.content')}}</th>
          <th scope="col">{{$t('vendor_pages.image')}}</th>
          <th scope="col">{{$t('vendor_pages.vedio')}}</th>
          <th scope="col">{{$t('vendor_pages.status')}}</th>
          <th scope="col">{{$t('vendor_pages.created')}}</th>
          <th scope="col">{{$t('vendor_pages.updated')}}</th>
          <th scope="col">{{$t('vendor_pages.operations')}}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app) in VendorPages" :key="app" class="text-center">
          <td>{{app.vendor_id}}</td>
          <td>{{app.page_type}}</td>
          <td>{{app.title}}</td>
          <td>{{app.content}}</td>
          <td>{{app.image}}</td>
          <td>{{app.vedio}}</td>
          <td>{{app.status}}</td>
          <td>{{app.created.split('T')[0]}}</td>
          <td>{{app.updated.split('T')[0]}}</td>

          <td class="d-flex">
            <a
              class="btn btn-primary mx-1"
              @click="editVendorPages(app)"
              href="javascript: void(0);"
              role="button"
            >{{ $t("popups.edit") }}</a>
            <a
              class="btn btn-danger"
              href="javascript: void(0);"
              role="button"
              @click="deleteVendorPages(app)"
            >{{ $t("popups.delete") }}</a>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- end  table -->
    <!-- Button trigger modal -->

    <div v-if="tot_pages == 20">
      <!--   Apps  paginate     -->
      <ul class="paginate paginate-rounded justify-content-center mb-2">
        <li class="page-item" :class="{ disabled: page == 1 }">
          <a
            class="page-link"
            href="javascript: void(0);"
            @click="get(page - 1)"
            aria-label="Previous"
          >
            <i class="mdi mdi-chevron-left"></i>
          </a>
        </li>
        <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
          <a class="page-link" href="javascript: void(0);" @click="get(p)">
            {{
            p
            }}
          </a>
        </li>

        <li class="page-item" :class="{ disabled: page == total_pages }">
          <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
            <i class="mdi mdi-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
    <!--end-->
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>




